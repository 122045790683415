import 'twin.macro';
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Heading, Paragraph } from '../atoms';

const Hero = () => (
  <div tw="container mx-auto px-4 mt-12">
    <div tw="flex items-center flex-wrap justify-between">
      <div tw="md:w-6/12 w-full">
        <Heading isLarge>About Us</Heading>
        <div tw="mt-6 lg:mr-14 md:mr-6 mr-0">
          <Paragraph>
            <span tw="text-primary font-semibold">Start</span>
            <span tw="text-secondary font-semibold">SO</span> is an IT solutions
            provider recognized as a technology expert established in 2021 based
            ot of Bangalore.Making our footprints globally with our technology
            solutions which simplifies the business to perform their day-to-day
            activities digitally with ease.
          </Paragraph>
          <Paragraph>
            <span tw="text-primary font-semibold">Start</span>
            <span tw="text-secondary font-semibold">SO</span> provides state of
            the art consulting, design, development and services to optimize
            software processes for businesses. We follow a consultative-driven
            approach for providing end-to-end expertise in providing mobility
            solutions, besides being one of the top service providers on SaaS
            technologies.
          </Paragraph>
          <Paragraph>
            We pride ourselves on the fact that we have a wide span of
            technology experts under the same roof - one of the reasons why we
            are not just fan-favorite, but also recognized among the best
            technology companies!
          </Paragraph>
        </div>
      </div>
      <div tw="md:w-6/12 w-full">
        <StaticImage
          src="../../../static/images/about-hero.jpg"
          alt="startso"
          placeholder="blurred"
        />
      </div>
    </div>
  </div>
);
export default Hero;
