import { styled } from 'twin.macro';
import React from 'react';

import { Heading, Paragraph } from '../atoms';

const Container = styled.div`
  background: url('/images/about-bg.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
`;
const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.5);
`;

const MainHero = () => (
  <Container>
    <Wrapper>
      <div tw="container mx-auto px-4 md:py-32 py-16">
        <div tw="w-full text-center max-w-screen-md mx-auto">
          <Heading hasTextWhite>IT Solutions Company</Heading>
          <Paragraph hasTextWhite>
            We offer services in key areas of enterprise mobile app development,
            smart software products, customer relationship management (CRM), and
            products which solve the gaps across all the industries digitally
            with our smart web & mobile applications.
          </Paragraph>
        </div>
      </div>
    </Wrapper>
  </Container>
);
export default MainHero;
