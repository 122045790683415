/* eslint-disable jsx-a11y/media-has-caption */
import { styled } from 'twin.macro';
import React from 'react';

import Seo from '../components/Seo';
import Layout from '../components/Layout';
import config from '../utils/config';
import Header from '../components/Header';
import Hero from '../components/about/Hero';
import MainHero from '../components/about/MainHero';

const Container = styled.div``;

const AboutPage = () => (
  <Layout hideHeader>
    <Seo
      title="About Us"
      description={config.description}
      url={config.siteUrl}
      image={config.logo}
    />
    <Container>
      <Header />
      <MainHero />
      <Hero />
    </Container>
  </Layout>
);
export default AboutPage;
